import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from '../Utils';
import FollowHandler from './FollowHandler';

const FriendsSuggestion = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [playOnce, setPlayOnce] = useState(true);
    const [friendsSuggestion, setFriendsSuggestion] = useState([]);
    const userData = useSelector((state) => state.userReducer);
    const usersData = useSelector((state) => state.usersReducer);

    useEffect(() => {
    const notFriendList = () => {
        let array = [];
        usersData.map((user) => {
                if (user.uuid !== userData.uuid && !user.followers.includes(userData.uuid)) {
                    return array.push(user.uuid)
                } else return null
        });

        array.sort(() => 0.5 - Math.random());
        if (window.innerHeight > 780) {
            array.length = 5;
        } else if (window.innerHeight > 720) {
            array.length = 4;
        } else if (window.innerHeight > 615) {
            array.length = 3;
        } else if (window.innerHeight > 540) {
            array.length = 1;
        } else {
            array.length = 0;
        }
        setFriendsSuggestion(array);
        };

        if (playOnce && !isEmpty(usersData[0]) && !isEmpty(userData.uuid)) {
            notFriendList();
            setIsLoading(false);
            setPlayOnce(false);
        }
    }, [usersData, userData, playOnce]);


    return (
        <div className="get-friends-container">
            <h4>Suggestions</h4>
            {isLoading ? (
                <div className="icon">
                    <i className='fas fa-spinner fa-pulse'></i>
                </div>
            ) : (
                <ul>
                    {friendsSuggestion && friendsSuggestion.map((user) => {
                        for(let i = 0; i < usersData.length; i++) {
                            if (user === usersData[i].uuid) {
                                return (
                                    <li className="user-hint" key={user}>
                                        <img src={usersData[i].picture} alt="user-pic" />
                                        <p>{usersData[i].username}</p>
                                        <FollowHandler idToFollow={usersData[i].uuid} type={"suggestion"}/>
                                    </li>
                                )
                            } 
                        } return null
                        })
                    }
                </ul>
            )}
        </div>
    );
};

export default FriendsSuggestion;